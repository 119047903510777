import React from "react"
import { css } from "@emotion/core"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import {
  desktopMediaQuery,
  fontSizes,
  imageStyles,
  colors,
  sizes,
} from "../../styles/constants"
import { SecondaryButton, PrimaryButton } from "../Button/Button"
import { useLocalized } from "../LocalizedLink"
import { defaultLang } from "../../context"
import { Title } from "../styledComponents"
import PWithBrWithMoreButton from "../PWithBrWithMoreButton"

function Introduction({ introduction, images, title }) {
  const [navigate, lang] = useLocalized()

  const handleGalleryClick = () => {
    navigate("/galeria")
  }

  return (
    <StaticQuery
      query={graphql`
        query IntroSection {
          tree: file(relativePath: { eq: "yg.jpg" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <div css={styles.container}>
            <div css={styles.pictures.container}>
              <Img
                fluid={images.intro1.childImageSharp.fluid}
                style={styles.pictures.image1}
                className="img1"
              />
              <Img
                fluid={images.intro2.childImageSharp.fluid}
                style={styles.pictures.image2}
                className="img2"
              />
              <div css={styles.pictures.buttonContainer}>
                <SecondaryButton onClick={handleGalleryClick}>
                  {lang === defaultLang ? "Galéria" : "Gallery"}
                </SecondaryButton>
              </div>
            </div>
            <Title css={styles.title} as={"h1"}>
              {title}
            </Title>
            <div css={styles.text}>
              <PWithBrWithMoreButton>{introduction}</PWithBrWithMoreButton>
              <PrimaryButton
                css={styles.bookButton}
                onClick={() => {
                  navigate("/foglalas")
                }}
              >
                {lang === defaultLang ? "FOGLALJ MOST!" : "BOOK NOW!"}
              </PrimaryButton>
            </div>
          </div>
        )
      }}
    />
  )
}

export default Introduction

const styles = {
  container: css`
    display: grid;
    grid-template-rows: 3rem auto auto;
    grid-template-columns: 50% 50%;
    grid-template-areas:
      "title title"
      "picture picture"
      "text text";
    ${desktopMediaQuery} {
      column-gap: 2rem;
      grid-template-columns: 50% fit-content(50%);
      grid-template-rows: 5rem auto auto;
      grid-template-areas:
        "picture title"
        "picture text"
        "picture text";
    }
  `,
  pictures: {
    container: css`
      grid-area: picture;
      padding-bottom: 2rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      ${desktopMediaQuery} {
        display: block;
        & .img1 {
          width: 50% !important;
          ${imageStyles.boxShadow};
          transform: translate(75%);
          z-index: 10;
        }
        & .img2 {
          width: 65% !important;
          ${imageStyles.boxShadow};
          transform: translateY(-20%);
          z-index: 5;
        }
      }
    `,
    image1: {
      width: "100%",
      margin: "0.2rem 0",
      borderRadius: "4px",
    },
    image2: { width: "50%", borderRadius: "4px" },
    buttonContainer: css`
      width: 50%;
      display: flex;
      justify-content: center;
      ${desktopMediaQuery} {
        width: 50%;
        transform: translateY(-50%);
        & button {
          width: 100%;
        }
      }
    `,
  },
  title: css`
    grid-area: title;
    color: ${colors.primary800};
    ${desktopMediaQuery} {
      font-size: 3rem;
    }
  `,
  text: css`
    grid-area: text;
    line-height: 2rem;
    ${desktopMediaQuery} {
      line-height: 2.5rem;
      font-size: ${fontSizes.medium};
    }
  `,
  bookButton: css`
    margin-top: ${sizes.smallScale.xxl};
  `,
}
