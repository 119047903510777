import React from "react"
import { css } from "@emotion/core"
import BackgroundImage from "gatsby-background-image"
import { desktopMediaQuery, shadow } from "../../styles/constants"
import { PrimaryButton } from "../Button"
import { useLocalized } from "../LocalizedLink"

function BookNowSection({ text, image, buttonText }) {
  const [navigate] = useLocalized()

  const handleBookNow = () => {
    navigate("/foglalas")
  }
  const backgroundFluidImageStack = [
    image.childImageSharp.fluid,
    `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))`,
  ].reverse()
  return (
    <BackgroundImage
      Tag="div"
      fluid={backgroundFluidImageStack}
      backgroundColor={"black"}
      css={styles.backgroundImg}
    >
      <div css={styles.container}>
        <h1 className="title has-text-centered" css={styles.text}>
          {text}
        </h1>
        <PrimaryButton css={styles.bookButton} onClick={handleBookNow}>
          {buttonText}
        </PrimaryButton>
      </div>
    </BackgroundImage>
  )
}

export default BookNowSection

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 20rem;
    ${desktopMediaQuery} {
      height: 30rem;
    }
  `,
  backgroundImg: css`
    background-position: center !important;
  `,
  text: css`
    margin-top: 5rem;
    color: white;
    ${desktopMediaQuery} {
      margin-top: 12rem;
      font-size: 3rem;
    }
  `,
  bookButton: css`
    margin-bottom: 2rem;
    ${shadow.light};
    ${desktopMediaQuery} {
      margin-bottom: 12rem;
    }
  `,
}
