import React from "react"
import { css } from "@emotion/core"
import {
  colors,
  desktopMediaQuery,
  imageStyles,
  baseListStyle,
  subtitleSize,
} from "../../styles/constants"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

function Possibilities({
  options,
  images,
  title,
  inHouseTitle,
  aroundTitle,
  extraImage1,
  extraImage2,
}) {
  const houseElements = []
  const areaElements = []
  options.forEach(option => {
    if (option.category === "house") {
      houseElements.push(option.name)
    } else if (option.category === "area") {
      areaElements.push(option.name)
    }
  })
  return (
    <StaticQuery
      query={graphql`
        query PossibilitiesSection {
          wheel: file(relativePath: { eq: "kerekkkkkkkkk.png" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <div css={styles.container}>
            {/* <h1 className="title" css={styles.title}>
                                {title}
                            </h1> */}
            <div css={styles.possibilitiesContainer}>
              {houseElements.length !== 0 && (
                <div css={styles.listContainer}>
                  <h2 className="title" css={styles.subtitle}>
                    {inHouseTitle}
                  </h2>
                  <ul css={styles.list}>
                    {houseElements.map(element => (
                      <li css={styles.listItem} key={element}>
                        {element}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {areaElements.length !== 0 && (
                <div css={styles.listContainer}>
                  <h2 className="title" css={styles.subtitle}>
                    {aroundTitle}
                  </h2>
                  <ul css={styles.list}>
                    {areaElements.map(element => (
                      <li css={styles.listItem} key={element}>
                        {element}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div css={styles.imagesContainer}>
                <Img
                  fluid={images.jacuzzi.childImageSharp.fluid}
                  className="jacuzzi__img"
                />
                <Img
                  fluid={images.tripForest.childImageSharp.fluid}
                  className="tripForest__img"
                />
                <Img
                  fluid={images.squirel.childImageSharp.fluid}
                  className="squirel__img"
                />
                {/* <Img
                  fluid={images.campFire.childImageSharp.fluid}
                  className="campFire__img"
                /> */}
                <Img
                  fluid={images.extraImage1.childImageSharp.fluid}
                  className="otherImg1"
                />
                {/* <Img
                  fluid={images.extraImage2.childImageSharp.fluid}
                  className="otherImg2"
                /> */}
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export default Possibilities

const styles = {
  container: css`
    ${desktopMediaQuery} {
      padding-top: 3rem;
    }
  `,
  title: css`
    ${desktopMediaQuery} {
      font-size: 3rem;
    }
  `,
  subtitle: css`
    color: ${colors.primary};
    ${subtitleSize};
  `,
  possibilitiesContainer: css`
    display: flex;
    flex-wrap: wrap;
  `,
  listContainer: css`
    padding-right: 6rem;
    @media (min-width: 60rem) {
      padding-right: 3rem;
    }
  `,
  list: css`
    padding-bottom: 2rem;
  `,
  listItem: css`
    ${baseListStyle};
    &:before {
      content: "-  ";
    }
  `,
  imagesContainer: css`
    flex-grow: 1;
    display: none;
    min-width: 18rem;
    display: block;
    & .jacuzzi__img,
    & .tripForest__img,
    & .campFire__img,
    & .otherImg1,
    & .otherImg2,
    & .squirel__img {
      ${imageStyles.borderRadius};
      ${imageStyles.boxShadow};
    }
    & .jacuzzi__img {
      width: 100%;
    }
    & .tripForest__img {
      display: none;
    }
    & .campFire__img {
      width: 100%;
      transform: translateY(10%);
    }
    & .squirel__img {
      display: none;
    }
    & .otherImg1 {
      display: none;
    }
    & .otherImg2 {
      display: none;
    }
    @media (min-width: 60rem) {
      & .jacuzzi__img {
        width: 70%;
      }
      & .tripForest__img {
        display: block;
        width: 40%;
        transform: translate(15%, -4%);
      }
      & .campFire__img {
        width: 70%;
        transform: translate(40%, -70%);
      }
      & .squirel__img {
        display: block;
        width: 28%;
        transform: translate(213%, -213%);
      }
      & .otherImg1 {
        display: none;
      }
      & .otherImg2 {
        display: none;
      }
    }
    @media (min-width: 75rem) {
      & .jacuzzi__img {
        width: 50%;
      }
      & .tripForest__img {
        display: block;
        width: 40%;
        transform: translate(39%, -5%);
      }
      & .campFire__img {
        width: 50%;
        transform: translate(95%, -130%);
      }
      & .squirel__img {
        display: block;
        width: 28%;
        transform: translate(213%, -213%);
      }
      & .otherImg1 {
        display: block;
        width: 50%;
        transform: translate(-7%, -167%);
      }
      & .otherImg2 {
        display: block;
        width: 50%;
        transform: translate(70%, -231%);
      }
    }
  `,
}
