import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import BackgroundImage from "gatsby-background-image"
import Introduction from "../components/LandingSections/IntroductionSection"
import { desktopMediaQuery, colors } from "../styles/constants"
import Possibilities from "../components/LandingSections/PossibilitiesSection"
import BookNowSection from "../components/LandingSections/BookNowSection"
// import RecommendationSection from "../components/LandingSections/RecommendationSection"
import { Section } from "../components/styledComponents"

export function MainPageTemplate(props) {
  const {
    header: headerImg,
    intro2,
    intro1,
    tripForest,
    jacuzzi,
    // campFire,
    squirel,
    bookBg,
    recommendationBg,
    extraImage1,
  } = props.images
  const backgroundFluidImageStack = [
    headerImg.childImageSharp ? headerImg.childImageSharp.fluid : headerImg,
    `linear-gradient(to top, rgba(144, 124, 106, 0.5) 2%, transparent)`,
  ].reverse()
  return (
    <div>
      {/* <h1 className="title is-1 has-text-centered">{props.title}</h1> */}
      <BackgroundImage
        Tag="section"
        fluid={backgroundFluidImageStack}
        backgroundColor={`#040e18`}
        css={styles.headerImg}
      >
        <div css={styles.headerImgContainer}>
          <h1 css={styles.headerTitle}>{props.title}</h1>
        </div>
      </BackgroundImage>
      <div css={styles.container}>
        <Section
          css={css`
            max-width: 100%;
          `}
        >
          <Introduction
            title={props.introTitle}
            introduction={props.introduction}
            images={{ intro1, intro2 }}
          />
        </Section>
        <Section
          css={css`
            max-width: 100rem;
            width: 100%;
          `}
        >
          <Possibilities
            title={props.optionsTitle}
            inHouseTitle={props.inHouseTitle}
            aroundTitle={props.aroundTitle}
            options={props.options}
            images={{
              tripForest,
              jacuzzi,
              // campFire,
              squirel,
              extraImage1,
            }}
          />
        </Section>
        <BookNowSection
          text={props.closingText}
          buttonText={props.bookNow}
          image={bookBg}
        />
      </div>
    </div>
  )
}

function MainPage({ data }) {
  const { markdownRemark, ...images } = data
  return <MainPageTemplate {...markdownRemark.frontmatter} images={images} />
}

export default MainPage

export const pageQuery = graphql`
  query MainPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        introTitle
        introduction
        optionsTitle
        inHouseTitle
        aroundTitle
        options {
          name
          category
        }
        closingText
        bookNow
      }
    }
    header: file(relativePath: { eq: "booking_bg.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    intro1: file(relativePath: { eq: "intro_kep_001.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    intro2: file(relativePath: { eq: "intro2.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tripForest: file(relativePath: { eq: "fel2.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    # campFire: file(relativePath: { eq: "fel34.png" }) {
    #   childImageSharp {
    #     fluid(quality: 100, maxWidth: 1920) {
    #       ...GatsbyImageSharpFluid_withWebp
    #     }
    #   }
    # }
    squirel: file(relativePath: { eq: "fel3.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    jacuzzi: file(relativePath: { eq: "squirel.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    extraImage1: file(relativePath: { eq: "felsorolas.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    # extraImage2: file(relativePath: { eq: "other2.jpg" }) {
    #   childImageSharp {
    #     fluid(quality: 100, maxWidth: 1920) {
    #       ...GatsbyImageSharpFluid_withWebp
    #     }
    #   }
    # }
    bookBg: file(relativePath: { eq: "fentlent.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    recommendationBg: file(relativePath: { eq: "recomm.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
  `,
  headerImgContainer: css`
    width: 100%;
    height: 20rem;
    position: relative;
    ${desktopMediaQuery} {
      height: 40rem;
    }
  `,
  headerImg: css`
    background-origin: border-box;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top !important;
  `,
  headerTitle: css`
    position: absolute;
    color: ${colors.coldGray};
    bottom: 5%;
    left: 3%;
    font-size: 4rem;
    ${desktopMediaQuery} {
      left: 50%;
      transform: translate(-50%);
      font-size: 6rem;
    }
  `,
}
