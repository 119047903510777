import React, { Fragment, useState } from "react"
import { pStyle } from "../styles/constants"
import { SecondaryButton } from "./Button"

function PWithBrWithMoreButton({ children, className }) {
  const [showMore, setShowMore] = useState(false)
  const rows = showMore
    ? children.split("\n")
    : children.split("\n").slice(0, 2)
  console.log({ rows })
  return (
    <div className={className}>
      {rows.map((t, i) => (
        <Fragment key={i}>
          <p css={pStyle.justified}>{t}</p>
          <br />
        </Fragment>
      ))}
      {!showMore && (
        <SecondaryButton onClick={() => setShowMore(true)}>
          Történet folytatása
        </SecondaryButton>
      )}
    </div>
  )
}
export default PWithBrWithMoreButton
